export default{
    
    filters:{
        toCurrency(value) {
            if(value==undefined){return ''}
          var formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumSignifantDigits: 2
          });
          return formatter.format(value);
            
        },
        formatDate(dateString) {
            const date = new Date(dateString);
                // Then specify how you want your dates to be formatted
            return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
        },
        formatDateForInput(dateString){

            var t= new Date(dateString);

            var dd = String(t.getDate()).padStart(2, '0');
            var mm = String(t.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = t.getFullYear();

            return yyyy + '-' + mm + '-' + dd
        },
        formatDateForInputToday(){

            var t= new Date();

            var dd = String(t.getDate()).padStart(2, '0');
            var mm = String(t.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = t.getFullYear();

            return yyyy + '-' + mm + '-' + dd
        },
        formatDateTime(dateString){
            const t = new Date(dateString);
            var dd = String(t.getDate()).padStart(2, '0');
            var mm = String(t.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yy = t.getFullYear().toString().substr(-2)
            var hh=String(t.getHours()).padStart(2, '0');
            var nn= String(t.getMinutes()).padStart(2, '0');
            var ss=String(t.getSeconds()).padStart(2, '0');

            return mm + '/' + dd + '/' + yy + ' ' + hh + ':' + nn + ':' + ss
        },
        formatTime(dateString){
            const t = new Date(dateString);
            var hh=String(t.getHours()).padStart(2, '0');
            var nn= String(t.getMinutes()).padStart(2, '0');
            var ss=String(t.getSeconds()).padStart(2, '0');

            return hh + ':' + nn + ':' + ss
        },
        formatDateTimeForToday(){
            const t = new Date();
            var dd = String(t.getDate()).padStart(2, '0');
            var mm = String(t.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yy = t.getFullYear().toString().substr(-2)
            var hh=String(t.getHours()).padStart(2, '0');
            var nn= String(t.getMinutes()).padStart(2, '0');
            var ss=String(t.getSeconds()).padStart(2, '0');

            return mm + '/' + dd + '/' + yy + ' ' + hh + ':' + nn + ':' + ss
        },
        formatPhone(phn) {
            console.log('formatph ' + phn)
            if(phn==null){return ''}
            if(phn==''){return ''}
            phn=phn.replace('-','').replace('(','').replace(')','').replace(' ','')
            
            phn=phn.replace('.','')
            phn=phn.replace('.','')
            //console.log('cleannum ' + phn)
            if(phn.length==11 && phn.substring(0,1)=='1'){phn=phn.substring(1)}
    
            phn = "(" + phn.substring(0,3) + ")" + phn.substring(3,6) + "-" + phn.substring(6);
            return phn
                
        },
      },
    methods:{
        capitalize(string){
            return string.charAt(0).toUpperCase() + string.slice(1);
          },
    }
}