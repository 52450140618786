<template>
    <div >
        <form  ref="lookupform" v-on:keyup.enter="Lookup()">
            <label class="titlelabel bottom10">Add Scheduled Report</label>   
        </form>
        <div class="vertFlexLeft">
            <label class="subtitlelabel bottom10">Select Report</label>
        </div>
        <div class="resultsdiv" >
            <div v-for="result in lookupResults" :key="result.ID" :value="result.ID" >
                <lookupresult :topLeft="result.Name"   @click="getReport(result.ID)"
                    :selected="isSelected(result)">
                
                </lookupresult>  
            </div>
        </div>
        
        <div class="paramdiv" v-if="isSelectedResult">
            <div class="vertFlexLeft">
                <label class="subtitlelabel bottom10">Select Parameters</label>
            </div>
            <div class="vertFlexLeft" v-for="param in userParameters" :key="param.ID" >
                <p class="editLabel">{{param.Name}}</p>
                <input class="editText" :type="paramInputType(param)" v-if="!paramIsSelect(param)" v-model="param.ParameterID"/>
                <select name="" id="" class='lookupSelect'  v-if="paramIsSelect(param)" v-model="param.ParameterID">
                    <option v-for="opt in param.Values" :key="opt.ID" :value="opt.ID" >
                        {{opt.Name}}
                    </option>
                </select>
            </div>
            
        </div>
        
        <div class="margin10">
            <button class="gobutton buttonhighlight" @click="saveandschedule()"  v-if="reportSelected">Schedule this Report</button>
            <button class="gobutton buttonprimary" @click="cancel()" >Cancel</button>
        </div>
        
    </div>
</template>

<script>
import {ReportHTTP} from '../../repository'
import format from '../../format.js'
import smallbutton from '../controls/button.vue'
import formentrystacked from '../controls/entry-stacked.vue'
import entrySelect from '../controls/entry-select'
import lookupresult from '../controls/lookupresult.vue'
import linkbutton from '../controls/linkbutton.vue'
export default {
    name:'reportlookup',
    mixins:[ format],
    components:{
        smallbutton,
        formentrystacked,
        entrySelect,
        lookupresult,
        linkbutton
    },
    data(){
        return{
            locationID:1,
            lookupTypeList:[],
            lookupPromptText:"Lookup Report",
            responseMessage:'',
            lookupResults:[],
            selectedResult:{'ID':0},
            isSelectedResult:false
        }
    },
    methods:{
        lookup(){
            let req = {
                SalonID:0,
                ReportTypeID:-1
            }
            this.pleaseWait=true;
            ReportHTTP.post('reportlist',req)
            .then(response=>{
                this.lookupResults = response.data.ReportList
                this.pleaseWait=false;
                console.log(response)
            })
            .catch(e=>{
                this.pleaseWait=false;
                if(e.message=='Network Error'){
                    this.errorMessage = "Report Error: Cannot Reach API"
                }
                this.errorMessage = "Report Error: " + e.message;

                console.log(e)
                return e
            });
            
        },
        Refresh(){

        },
        setDates(){
            console.log('setdates')
            this.selectedResult.Parameters.forEach(myFunction2)
            
            function myFunction2(item, index, arr) {
                let param=arr[index]
                console.log('controltype ' + param.ControlType)
                if(param.ControlType==3){
                    console.log(param.CurrentValue)

                    var t= new Date(param.CurrentValue);

                    var dd = String(t.getDate()).padStart(2, '0');
                    var mm = String(t.getMonth() + 1).padStart(2, '0'); //January is 0!
                    var yyyy = t.getFullYear();

                    param.CurrentValue=yyyy + '-' + mm + '-' + dd
                }

            } 
        },
        getReport(reportID){
            this.errorMessage='';

            let req = {
                ReportID:reportID,
                SalonID:this.locationID,
                ProcessParametersForScheduling:true
            }
            this.pleaseWait=true;
            ReportHTTP.post('report',req)
            .then(response=>{
                this.selectedResult = response.data.Report
                this.setDates();
                this.isSelectedResult=true;
                this.pleaseWait=false;
            })
            .catch(e=>{
                this.pleaseWait=false;
                return e
            });
            
        },
        isSelected(result){
            return result.ID==this.selectedResult.ID
        },
        paramInputType(param){
            switch(param.ControlType){
                case 3:
                    return "date"
                    break;
                
                default:
                    return "text"
                    break;
            }

        },
        paramIsSelect(param){
            if(param.ControlType=="2"){
                return true;
            }else{
                return false
            }
        },
        cancel(){
            this.$emit("cancel")
        },
        saveandschedule(){
            //save!
            
            
             let req = {
                scheduledReport:{
                    ReportID:this.selectedResult.ID,
                    LocationID:this.selectedResult.LocationID,
                    IsActive:true,
                    Name:this.selectedResult.Name,
                    Parameters:this.selectedResult.Parameters
                }
            }

            this.pleaseWait=true;
            ReportHTTP.post('savescheduledreport',req)
            .then(response=>{
                console.log(response.data)
                var newReportId=response.data.ScheduledReport.ID
                this.$emit("saveandschedule",newReportId)
                this.pleaseWait=false;
                console.log(response)
            })
            .catch(e=>{
                this.pleaseWait=false;
                if(e.message=='Network Error'){
                    this.errorMessage = "Report Error: Cannot Reach API"
                }
                this.errorMessage = "Report Error: " + e.message;

                console.log(e)
                return e
            });

            

        }
    },
    computed:{
        reportSelected(){
            if(this.selectedResult==null){return false}
            if(this.selectedResult.ID==0){return false}
            return true
        },
        userParameters(){   
            if(this.selectedResult==undefined){return} 
            let tempUsers = this.selectedResult.Parameters
            if(tempUsers!=undefined){
                tempUsers = tempUsers.filter((item)=>{
                    return (item.UserMayEnter)
                })
                return tempUsers
            }
                
        },
       
    },
    mounted(){
       this.lookup()

    }
}
</script>

<style>
.resultsdiv{
    height:320px;
    overflow-x:hidden;
    overflow-y:scroll;
}
.paramdiv{
    margin-top:20px;
    margin-bottom:20px;
}
.editLabel{
    margin:10px 0px 0px 0px;
}
</style>