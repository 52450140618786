export default{
    data(){
        return{
            //colors
            primaryColor:'#E32323',
            highlightColor:'#F7C142',
            alertColor:'#E32323',
            textColor:'#0E2049',
            lightTextColor:'#FFFFFF',
        }
    },
    methods:{
        setColors(){
            this.primaryColor = window.PRIMARY_COLOR
            this.highlightColor = window.HIGHLIGHT_COLOR
            this.alertColor=window.ALERT_COLOR
            this.textColor=window.TEXT_COLOR
            this.lightTextColor=window.LIGHT_TEXT_COLOR
        },
        
    },
    computed:{
        configStyle(){
            return{
                '--primary-color':this.primaryColor,
                '--highlight-color':this.highlightColor,
                '--alert-color':this.alertColor,
                '--text-color':this.textColor,
                '--light-text-color':this.lightTextColor,
            }
        }
    }
    
}